import React, { ReactNode } from "react";
import { Typography } from "@mui/material";
import CustomModal from "../../ui/Modal";
import ModalContent from "../../ui/Modal/ModalContent";
import ModalFooter from "../../ui/Modal/ModalFooter";
import ModalHeader from "../../ui/Modal/ModalHeader";
import colors from "../../../config/theme/colors";

interface Props {
  title: string;
  content: string | ReactNode;
  type?: "info" | "danger";
  cancelButtonText?: string;
  confirmButtonText?: string;
  confirmButtonDisabled?: boolean;
  disableDismiss?: boolean;
  isOpen: boolean;
  isLoading?: boolean;
  onClose: (success: boolean) => void;
  onClosed?: () => void;
  onCancel?: () => void;
  onConfirm?: () => void;
  children?: ReactNode;
}

export default function ConfirmDialog({
  type = "info",
  title,
  content,
  cancelButtonText,
  confirmButtonText,
  confirmButtonDisabled,
  disableDismiss,
  isOpen,
  isLoading,
  onClose,
  onClosed,
  onCancel,
  onConfirm,
  children,
}: Props) {
  const handleClose = () => onClose(false);

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
      return;
    }
    onClose(true);
  };

  return (
    <CustomModal
      onClose={handleClose}
      onClosed={onClosed}
      open={isOpen}
      disableDismiss={disableDismiss}
      Header={<ModalHeader title={title} />}
      Footer={
        <ModalFooter
          cancelButtonText={cancelButtonText}
          onCancelButtonClick={onCancel ?? handleClose}
          confirmButtonText={confirmButtonText}
          confirmButtonProps={{
            isLoading,
            disabled: confirmButtonDisabled,
            color: type === "info" ? "primary" : "error",
          }}
          onConfirmButtonClick={handleConfirm}
        />
      }
      Content={
        <ModalContent>
          <Typography variant="body4" color={colors.text.secondaryDark}>
            {content}
          </Typography>
          {children}
        </ModalContent>
      }
    />
  );
}
