import { matchPath, useLocation } from "react-router";
import { useCallback, useMemo } from "react";
import {
  NavigationItemProps,
  NavigationItems,
  OrganisationStatus,
} from "#customTypes/types";
import { getDefaultNavigationConfiguration } from "#containers/layout/Navigation/utils";
import useOrganisationStatus from "#components/pages/BusinessLab/useOrganisationStatus";
import useSelectedOrganisation from "#components/pages/BusinessAccount/useSelectedOrganisation";
import { NavigationAction } from "#constants/navigation";
import { useMediaQueryProvider } from "#shared/context/MediaQueryProvider";
import {
  getOrganisationDomain,
  useGetWlaOrganisation,
} from "../../../../utils/organisation";
import { getEnvironmentUrl } from "#shared/utils/url.utils";
import useGodView from "#containers/GodView/useGodView";
import { useSelectorTyped } from "#utils/index";
import { selectUser } from "#features/user/userSlice";
import { isUserSuperAdmin } from "#utils/userUtil";
import useTranslation from "#utils/useTranslation";

const useNavigation = (navItems?: NavigationItems) => {
  const { t } = useTranslation();
  const { isTabletOrMobile } = useMediaQueryProvider();
  const { pathname } = useLocation();

  const domainOrganisation = getOrganisationDomain(window.location.href);
  const { organisation } = useGetWlaOrganisation(domainOrganisation);
  const { organisationId } = useSelectedOrganisation();
  const {
    organisationStatus,
    hasVerifiedOrganisation,
    hasPendingOrganisation,
    hasResearchOrganisation,
  } = useOrganisationStatus();
  const { isEnabled: isGodViewEnabled } = useGodView();
  const { profile } = useSelectorTyped(selectUser);

  const configuration = useMemo(() => {
    const config = getDefaultNavigationConfiguration(pathname);
    return {
      ...config,
      items: navItems ?? config.items,
    };
  }, [navItems, pathname]);

  const visible: Record<string, boolean> = useMemo(
    () => ({
      [NavigationAction.following]: !isGodViewEnabled,
      [NavigationAction.businesslab]:
        !isGodViewEnabled && !hasVerifiedOrganisation && !organisation,
      [NavigationAction.businesslab_external]: hasVerifiedOrganisation,
      [NavigationAction.knowledgeAi]: hasVerifiedOrganisation || hasResearchOrganisation,
      [NavigationAction.business_settings]: isTabletOrMobile && hasVerifiedOrganisation,
      [NavigationAction.business_centre]: !isTabletOrMobile && hasVerifiedOrganisation,
      [NavigationAction.god_view]: isUserSuperAdmin(profile),
    }),
    [
      hasVerifiedOrganisation,
      hasResearchOrganisation,
      isTabletOrMobile,
      organisation,
      isGodViewEnabled,
      profile,
    ]
  );

  const selectLabelAndColor = useCallback(
    (item: NavigationItemProps) => {
      let label = item.label ? t(item.label) : undefined;
      let labelColor = item.labelColor;

      if (item.id === NavigationAction.businesslab) {
        if (hasPendingOrganisation) {
          label = t("navigation.labels.pending");
          labelColor = "secondary";
        } else if (organisationStatus === OrganisationStatus.REJECTED) {
          label = "";
        }
      }

      return { label, labelColor };
    },
    [organisationStatus, hasPendingOrganisation]
  );

  const enrichURL = useCallback(
    (item: NavigationItemProps) =>
      getEnvironmentUrl(item.id).replace(":organisationId", String(organisationId)),
    [organisationId]
  );

  const items = useMemo(
    () =>
      configuration.items
        .filter((item) => visible[item.id] ?? true)
        .map((item) => {
          const { label, labelColor } = selectLabelAndColor(item);

          return {
            ...item,
            id: enrichURL(item) as NavigationAction,
            name: t(item.name),
            label,
            labelColor,
          };
        }),
    [organisationStatus, visible, pathname]
  );

  const selectedItem = useMemo(() => {
    if (pathname.includes("channel")) return undefined;
    const item = items.find(({ id }) => matchPath(id, pathname));
    return item ? item.id : configuration.defaultSelected;
  }, [pathname, configuration.defaultSelected]);

  return { items, selectedItem, pathname };
};

export default useNavigation;
