import { client } from "#apis/axios";
import {
  GetChannelMembersRequest,
  GetChannelMembersResponse,
  GetMyFollowedChannelsRequest,
  GetMyFollowedChannelsResponse,
  JoinLeaveChannelRequest,
  RemoveChannelMemberRequest,
  SearchChannelsRequest,
  SearchChannelsResponse,
} from "#customTypes/channel";

export namespace ChannelsApi {
  const BASE_PREFIX = "/community/channels";

  export const getFollowedChannels = async (
    params: GetMyFollowedChannelsRequest
  ): Promise<GetMyFollowedChannelsResponse> => {
    const { data } = await client.get<GetMyFollowedChannelsResponse>(
      `${BASE_PREFIX}/my-followed-channels`,
      { params, headers: { WLA: params.organisationId } }
    );

    return data;
  };

  export const searchChannels = async (params: SearchChannelsRequest) => {
    const { data } = await client.get<SearchChannelsResponse>(`${BASE_PREFIX}/search`, {
      params,
    });

    return data;
  };

  export const getMembers = async (params: GetChannelMembersRequest) => {
    const { data } = await client.get<GetChannelMembersResponse>(
      `${BASE_PREFIX}/members`,
      {
        params,
      }
    );

    return data;
  };

  export const removeMember = async (body: RemoveChannelMemberRequest) => {
    await client.post(`${BASE_PREFIX}/remove-member`, body);
  };

  export const joinChannel = async (body: JoinLeaveChannelRequest) => {
    await client.post(`${BASE_PREFIX}/join`, body);
  };

  export const leaveChannel = async (body: JoinLeaveChannelRequest) => {
    await client.post(`${BASE_PREFIX}/leave`, body);
  };
}
