import SuggestedChannelsDesktop from "#components/pages/Feed/ChannelsSidebar/SuggestedChannels/SuggestedChannelsDesktop";
import SuggestedChannelsMobile from "#components/pages/Feed/ChannelsSidebar/SuggestedChannels/SuggestedChannelsMobile";
import SuggestedChannelsInterests from "#components/pages/Feed/ChannelsSidebar/SuggestedChannels/SuggestedChannelsInterests";
import { useFetchSuggestedChannelsQuery } from "#features/channel/channelsAPI";
import { useFetchMyInterestsQuery } from "#features/userInterests/userInterestsAPI";
import { css, styled } from "@mui/material";
import { useMediaQueryProvider } from "#shared/context/MediaQueryProvider";
import usePrevious from "#shared/utils/hooks/usePrevious";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { getOrganisationDomain, useGetWlaOrganisation } from "#utils/organisation";

interface Props {
  type?: "desktop" | "mobile";
}

export default function SuggestedChannels({ type = "desktop" }: Props) {
  const { pathname } = useLocation();
  const { isMobile, isDesktop } = useMediaQueryProvider();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const { data: interestsData, isLoading: isLoadingInterests } =
    useFetchMyInterestsQuery();

  const domainOrganisation = getOrganisationDomain(window.location.href);
  const { organisation, isInitialized } = useGetWlaOrganisation(domainOrganisation);

  const {
    data,
    isLoading: isLoadingChannels,
    isError: isErrorChannels,
    refetch: refreshChannels,
  } = useFetchSuggestedChannelsQuery(
    {
      organisationId: organisation?.organisationId,
    },
    {
      skip: !isInitialized,
    }
  );

  const channels = data?.data || [];

  const prevChannels = usePrevious(channels);

  const [showSelectInterests, setShowSelectInterests] = useState(false);

  const interests = interestsData?.map((interest) => interest.interestsId) || [];

  useEffect(() => {
    if (isLoadingInterests || interests.length) {
      return;
    }

    const interestsDismissed = localStorage.getItem("appInterestsDismissed");

    if (!interestsDismissed) {
      setShowSelectInterests(true);
    }
  }, [interests, isLoadingInterests]);

  useEffect(() => {
    if (!!prevChannels?.length || isErrorChannels) {
      refreshChannels();
    }
  }, [showSelectInterests, pathname]);

  const handleSelectInterests = () => {
    setShowSelectInterests(true);

    if (isMobile && wrapperRef.current) {
      window.scrollTo({ top: wrapperRef.current.offsetTop, behavior: "smooth" });
    }
  };

  const handleConfirmInterests = () => {
    localStorage.setItem("appInterestsDismissed", "true");
    setShowSelectInterests(false);
  };

  const content = () => {
    if (showSelectInterests) {
      return (
        <SuggestedChannelsInterests
          interests={interests}
          onConfirm={handleConfirmInterests}
        />
      );
    }

    if (isDesktop) {
      return (
        <SuggestedChannelsDesktop
          channels={channels}
          isLoadingChannels={isLoadingChannels}
          onSelectInterests={handleSelectInterests}
        />
      );
    }

    return (
      <SuggestedChannelsMobile
        channels={channels}
        isLoadingChannels={isLoadingChannels}
        onSelectInterests={handleSelectInterests}
      />
    );
  };

  if (
    isLoadingInterests ||
    (type === "desktop" && !isDesktop) ||
    (type === "mobile" && isDesktop)
  ) {
    return null;
  }

  return <Wrapper ref={wrapperRef}>{content()}</Wrapper>;
}

const Wrapper = styled("div")(
  ({ theme }) => css`
    position: relative;
    margin-top: 1rem;
    width: 100%;
    box-sizing: border-box;

    ${theme.breakpoints.up("md")} {
      position: sticky;
      box-sizing: border-box;
      top: 4.75rem;
      right: 0;
      margin: 0;
    }

    .filters.private:last-child {
      margin-bottom: 0;
    }
  `
);
