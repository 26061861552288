export enum QueryKeyEntity {
  brief = "brief",
  channels = "channels",
  chat = "chat",
  pollItems = "pollItems",
  briefBuilder = "brief-builder",
  superAdmin = "super-admin",
  unsplash = "unsplash",
}

export enum QueryKeyType {
  list = "list",
  details = "details",
  channel = "channel",
  token = "token",
}

export const BriefQueryKeys = {
  list: [QueryKeyEntity.brief, QueryKeyType.list],
  details: [QueryKeyEntity.brief, QueryKeyType.details],
};

export const ChatQueryKeys = {
  channel: [QueryKeyEntity.chat, QueryKeyType.channel],
  token: [QueryKeyEntity.chat, QueryKeyType.token],
};

export const PollItemsQueryKeys = {
  listByBriefRef: [QueryKeyEntity.pollItems, QueryKeyEntity.brief, QueryKeyType.list],
};
