import { ChannelsApi } from "#apis/channels/api";
import { ChannelsQueryKeys } from "#apis/channels/queryKeys";
import { CHANNELS_PAGE_SIZE } from "#constants/channel";
import {
  GetMyFollowedChannelsRequest,
  GetMyFollowedChannelsResponse,
} from "#customTypes/channel";
import { AppInfiniteQueryOptions, AppInfiniteQueryProps } from "#customTypes/reactQuery";
import { getInfiniteQueryOptions } from "#utils/paginationUtils";
import { useInfiniteQuery } from "@tanstack/react-query";

type Params = AppInfiniteQueryProps<GetMyFollowedChannelsRequest>;

interface Props {
  params: Params;
  options?: AppInfiniteQueryOptions<GetMyFollowedChannelsResponse, (string | Params)[]>;
}

export default function useGetMyFollowedChannels({ params, options }: Props) {
  return useInfiniteQuery({
    queryKey: [
      ...ChannelsQueryKeys.myFollowedChannels,
      { organisationId: params.organisationId },
    ],
    queryFn: ({ pageParam }) =>
      ChannelsApi.getFollowedChannels({
        ...params,
        page: pageParam,
        pageSize: CHANNELS_PAGE_SIZE,
      }),
    ...getInfiniteQueryOptions(),
    ...options,
  });
}
