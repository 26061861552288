import { useState, useCallback, useRef, useEffect, useMemo } from "react";
import { z } from "zod";
import { FormErrors, FormOptions } from "../../types/form";

type FormType<T> = {
  formValues: T;
  formErrors: FormErrors<T>;
  touchedState: Record<keyof T, boolean>;
};

// Define the hook's parameters and return types generically
type UseFormParams<T> = {
  schema: z.ZodSchema<T>;
  defaultValues?: T;
  options?: FormOptions<T>;
};

export type FormReturnType<T> = {
  hasError: boolean;
  hasChanges: boolean;
  formErrors: FormErrors<T>;
  formValues: T;
  getValue: (fieldName: keyof T) => any;
  setValue: (fieldName: keyof T, value: T[keyof T]) => void;
  setValues: (newValues: Partial<T>) => void;
  setDefaultValues: (newValues: T) => void;
  updateDefaultValues: () => void;
  getError: (fieldName: keyof T) => string | undefined;
  setError: (fieldName: keyof T, errorMessage: string) => void;
  setErrors: (errors: Record<keyof T, string>) => void;
  validate: () => boolean;
  validateField: (fieldName: keyof T, value: T[keyof T], skipTouched?: boolean) => void;
  submitForm: (callback: () => void) => void;
  resetForm: (data?: Partial<T>) => void;
};

function useZodForm<T>({
  schema,
  defaultValues,
  options,
}: UseFormParams<T>): FormReturnType<T> {
  const { validateOnChange, skipInitialValidation, mapError } = options || {};
  const defaultValuesRef = useRef<T>();
  const [version, setVersion] = useState(1);

  const [formData, setFormData] = useState<FormType<T>>({
    formValues: defaultValues || ({} as T),
    formErrors: {},
    touchedState: {} as Record<keyof T, boolean>,
  });

  useEffect(() => {
    if (defaultValues && !defaultValuesRef.current) {
      setFormData((prev) => ({
        formValues: defaultValues,
        formErrors: prev.formErrors,
        touchedState: Object.keys(defaultValues).reduce((acc, key) => {
          acc[key as keyof T] = false;
          return acc;
        }, {} as Record<keyof T, boolean>),
      }));
      defaultValuesRef.current = defaultValues;
      if (!skipInitialValidation) {
        validate();
      }
    }
  }, [defaultValues, skipInitialValidation]);

  const getValue = useCallback(
    (field: keyof T) => {
      return formData.formValues[field];
    },
    [formData]
  );

  const setValue = useCallback(
    (field: keyof T, value: T[keyof T]) => {
      const errors: FormErrors<T> = formData.formErrors;

      if (validateOnChange) {
        errors[field] = validateField(field, value, true);
      }

      setFormData((prevFormData) => ({
        ...prevFormData,
        formValues: { ...prevFormData.formValues, [field]: value },
        formErrors: errors,
      }));
    },
    [validateOnChange, formData.formErrors]
  );

  const setValues = useCallback((values: Partial<T>) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      formValues: { ...prevFormData.formValues, ...values },
    }));
  }, []);

  const setDefaultValues = useCallback((values: T) => {
    defaultValuesRef.current = values;
    setValues(values);
    validate();
  }, []);

  const getError = useCallback(
    (field: keyof T) => {
      return formData.formErrors[field];
    },
    [formData]
  );

  const setError = useCallback((field: keyof T, error: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      formErrors: { ...prevFormData.formErrors, [field]: error },
    }));
  }, []);

  const setErrors = useCallback((errors: FormErrors<T>) => {
    const filteredErrors = Object.entries(errors).filter(([, val]) => !!val);

    setFormData((prevFormData) => ({
      ...prevFormData,
      formErrors: { ...(Object.fromEntries(filteredErrors) as FormErrors<T>) },
    }));
  }, []);

  const validateField = useCallback(
    (fieldName: keyof T, value: T[keyof T], skipTouched?: boolean) => {
      if (skipTouched && formData.touchedState[fieldName]) {
        console.log("skipping touched field", fieldName);
        return;
      }

      try {
        // @ts-ignore: Zod typescript doesn't support generic types for pick
        const fieldSchema = schema.pick({ [fieldName]: true });
        fieldSchema.parse({ [fieldName]: value });
      } catch (error) {
        if (error instanceof z.ZodError) {
          const newErrors = error.flatten().fieldErrors;
          const message = newErrors[fieldName]?.[0];
          if (!message) {
            return;
          }
          return mapError?.(message) || message;
        }
      }

      return undefined;
    },
    [formData.formValues, formData.touchedState, schema]
  );

  const validate = useCallback(() => {
    let errors: FormErrors<T> = {};

    try {
      schema.parse(formData.formValues);
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors = error.flatten().fieldErrors;

        errors = Object.keys(newErrors).reduce((acc, key) => {
          const message = newErrors[key]?.[0];
          if (!message) {
            return acc;
          }
          acc[key as keyof T] = mapError?.(message) || message;
          return acc;
        }, {} as Partial<Record<keyof T, string>>);
      }
    }

    setFormData((prev) => ({ ...prev, formErrors: errors }));
    return !Object.values(errors).filter((val) => !!val).length;
  }, [validateField, mapError, formData.formValues, schema]);

  const submitForm = useCallback(
    (callback: () => void) => {
      if (!validate()) {
        return;
      }

      callback();
    },
    [validate]
  );

  const resetForm = useCallback(
    (data?: Partial<T>) => {
      if (!defaultValuesRef.current) {
        return;
      }

      setFormData((prev) => ({
        formValues: { ...(defaultValuesRef.current as T), ...data },
        formErrors: {},
        touchedState: Object.keys(prev.touchedState).reduce((acc, key) => {
          acc[key as keyof T] = false;
          return acc;
        }, {} as Record<keyof T, boolean>),
      }));
      if (!skipInitialValidation) {
        validate();
      }
    },
    [skipInitialValidation]
  );

  const updateDefaultValues = useCallback(() => {
    if (!validate()) {
      return;
    }

    defaultValuesRef.current = formData.formValues;
    setVersion((prev) => prev + 1);
  }, [formData.formValues, validate]);

  const hasChanges = useMemo(() => {
    if (!defaultValuesRef.current) {
      return false;
    }

    return (
      JSON.stringify(formData.formValues) !== JSON.stringify(defaultValuesRef.current)
    );
  }, [formData.formValues, version]);

  const hasError = useMemo(() => {
    return !!Object.values(formData.formErrors).filter((val) => !!val).length;
  }, [formData.formErrors, formData.formValues]);

  return {
    hasError,
    hasChanges,
    formErrors: formData.formErrors,
    formValues: formData.formValues,
    resetForm,
    getValue,
    setValue,
    setValues,
    setDefaultValues,
    updateDefaultValues,
    getError,
    setError,
    setErrors,
    validate,
    validateField,
    submitForm,
  };
}

export default useZodForm;
