import { PaletteColorOptions, createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import colors from "#shared/config/theme/colors";
import commonTheme from "#shared/config/ui/theme";
import { toRem, contrastColor, alphaColor, createColorShades } from "#shared/utils";
import { getInputStyles } from "#components/common/theme/utils";
import rounding from "#shared/config/theme/rounding";
import effects from "#shared/config/theme/effects";

export const getDefaultPrimary = (color?: string): PaletteColorOptions => {
  /* temporarily implemented to allow testing of different colors */
  if (typeof window !== "undefined" && sessionStorage.getItem("primaryColor")) {
    const primary = sessionStorage.getItem("primaryColor");
    if (typeof primary === "string" && primary.length) {
      return { main: primary };
    }
  }
  return { main: color || colors.brand.default };
};

interface Args {
  primary: PaletteColorOptions;
}

const INPUT_MIN_HEIGHT = 54;

export const useCreateTheme = (args: Args) => {
  const theme = useMemo(() => {
    const mainColor = (args.primary as any)?.main || colors.brand.default;
    const inputStyles = getInputStyles(mainColor);

    const primaryColor = createColorShades(mainColor);

    return createTheme({
      ...commonTheme,
      palette: {
        primary: primaryColor,
        secondary: {
          main: colors.grey[600],
          light: colors.grey[400],
          dark: colors.grey[800],
          contrastText: contrastColor(colors.brand.default),
        },
        warning: {
          ...commonTheme.palette.warning,
          main: colors.warning.default,
        },
        action: {
          disabledBackground: `${primaryColor.main}ca`,
        },
        error: {
          main: colors.error.default,
        },
      },
      typography: {
        allVariants: {
          fontFamily: "'Avenir', sans-serif",
          textTransform: "none",
        },
        headline1: {
          fontSize: toRem(56),
          lineHeight: toRem(72),
        },
        h1: {
          fontSize: toRem(48),
          lineHeight: toRem(60),
        },
        h2: {
          fontSize: toRem(32),
          lineHeight: toRem(42),
        },
        h3: {
          fontSize: toRem(28),
          lineHeight: toRem(32),
        },
        h4: {
          fontSize: toRem(24),
          lineHeight: toRem(28),
        },
        h5: {
          fontSize: toRem(18),
          lineHeight: toRem(26),
        },
        h6: {
          fontSize: toRem(16),
          lineHeight: toRem(24),
        },
        body1: {
          fontSize: toRem(20),
          lineHeight: toRem(36),
        },
        body2: {
          fontSize: toRem(18),
          lineHeight: toRem(32),
        },
        body3: {
          fontSize: toRem(16),
          lineHeight: toRem(28),
        },
        body4: {
          fontSize: toRem(14),
          lineHeight: toRem(20),
        },
        label1: {
          fontSize: toRem(16),
          lineHeight: toRem(24),
        },
        label2: {
          fontSize: toRem(14),
          lineHeight: toRem(24),
        },
        label3: {
          fontSize: toRem(12),
          lineHeight: toRem(16),
        },
      },
      components: {
        MuiTypography: {
          styleOverrides: {
            root: {
              fontFamily: "'Avenir', sans-serif",
            },
          },
          defaultProps: {
            variantMapping: {
              body1: "p",
              body2: "p",
              body3: "p",
              body4: "p",
              label1: "p",
              label2: "p",
              label3: "p",
            },
          },
        },
        MuiFormControl: {
          styleOverrides: {
            root: {
              width: "100%",
              input: {
                fontSize: toRem(14),
              },
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              borderRadius: "0.625rem !important",

              input: {
                fontSize: toRem(14),
              },
            },
          },
        },
        MuiAutocomplete: {
          styleOverrides: {
            root: {
              border: "none !important",

              "&.Mui-expanded": {
                backgroundColor: colors.input.backgroundFocused,
              },
              "&.MuiAutocomplete-root .MuiInputBase-root .MuiInputBase-input": {
                fontSize: toRem(14),
                lineHeight: toRem(20),
              },
            },
            paper: {
              marginTop: "1rem",
              boxShadow: effects.shadow.xl,
            },
            listbox: {
              borderRadius: "0.625rem !important",
              border: `1px solid ${colors.base.outline1}`,
              padding: 0,
            },
            option: {
              lineHeight: "2.25rem",
              borderBottom: `1px solid ${colors.base.outline1}`,

              ".MuiCheckbox-root": {
                padding: 0,
                paddingRight: "0.5rem",
              },
            },
            inputRoot: {
              minHeight: toRem(INPUT_MIN_HEIGHT),
            },
            noOptions: {
              fontSize: toRem(14),
              lineHeight: toRem(20),
            },
            endAdornment: {
              ".MuiAutocomplete-clearIndicator svg": {
                width: "1.25rem",
                height: "1.25rem",
              },
            },
          },
        },
        MuiPopper: {
          styleOverrides: {
            root: {
              zIndex: "1500 !important",
              li: {
                fontSize: toRem(14),
                lineHeight: toRem(20),
              },
            },
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            root: {
              fontSize: toRem(14),
            },
          },
        },
        MuiSelect: {
          styleOverrides: {
            root: {
              fontSize: toRem(14),
              lineHeight: toRem(20),
              borderRadius: "0.625rem",

              "&.MuiInputBase-adornedStart > svg:first-of-type": {
                marginRight: "0.5rem",
              },

              // Outlined border styles

              ".MuiOutlinedInput-notchedOutline": {
                ...inputStyles.base,
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                ...inputStyles.focused,
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                ...inputStyles.focused,
              },
              "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                ...inputStyles.disabled,
              },
            },
            select: {
              "& > *": {
                maxWidth: toRem(200),
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            },
            standard: {
              backgroundColor: colors.input.background,
            },
            outlined: {
              minHeight: toRem(21),
              backgroundColor: colors.input.background,
            },
          },
        },
        MuiList: {
          styleOverrides: {
            root: {
              border: `1px solid ${colors.base.outline1}`,
              borderRadius: "0.625rem",
              padding: 0,
              backgroundColor: colors.base.white,
            },
          },
        },
        MuiMenu: {
          styleOverrides: {
            paper: {
              borderRadius: "0.625rem",
              boxShadow: "0px 10px 10px -5px #0000000A, 0px 20px 25px -5px #0000001A",

              [commonTheme.breakpoints.up("md")]: {
                maxWidth: toRem(360),
              },
            },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              fontSize: toRem(14),
              padding: `${toRem(8)} ${toRem(16)}`,

              "&:not(:last-child)": {
                borderBottom: `1px solid ${colors.base.outline1}`,
              },

              "&.Mui-selected": {
                color: primaryColor.main,
                backgroundColor: `${primaryColor.ghost} !important`,
              },
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              transition: "background-color 0.2s ease-in-out",
              color: colors.text.secondaryDark,
              "&.MuiOutlinedInput-root-MuiSelect-root.MuiInputBase-root.MuiOutlinedInput-root":
                {
                  backgroundColor: "#F2F2F2!important",
                },
              ".MuiInputBase-inputMultiline": {
                fontSize: toRem(14),
                lineHeight: toRem(20),
                padding: 0,
              },
              "&.MuiOutlinedInput-root": {
                background: colors.base.white,

                "&.MuiInputBase-adornedEnd": {
                  paddingRight: toRem(8),
                },
              },
            },
            sizeSmall: {
              minHeight: toRem(40),
            },
          },
        },
        MuiInput: {
          styleOverrides: {
            root: {
              // remove underline
              "&::before": {
                display: "none",
              },
              "&::after": {
                display: "none",
              },
            },
          },
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              ".MuiOutlinedInput-root": {
                "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                  borderColor: `${commonTheme.palette.error.main} !important`,
                },

                ".MuiOutlinedInput-notchedOutline": {
                  ...inputStyles.base,
                },

                "&:hover .MuiOutlinedInput-notchedOutline": {
                  ...inputStyles.focused,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  ...inputStyles.focused,
                },

                "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                  ...inputStyles.disabled,
                },
              },
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              padding: `${toRem(16)} ${toRem(8)}`,
              fontSize: toRem(14),
              lineHeight: toRem(20),
              minWidth: toRem(156),
              borderRadius: toRem(10),
              transition: "all 0.2s ease-in-out",
              boxShadow: "none !important",

              // inherit from parent
              "& > svg": {
                width: "1.5rem",
                height: "1.5rem",
                path: {
                  fill: "currentColor",
                },
              },

              // icon styles
              "& .svg-inline--fa": {
                width: "1rem",
                height: "1rem",
              },

              // root styles
              "&:disabled": {
                backgroundColor: colors.grey[300],
                color: colors.base.white,
              },
            },
            // variants
            containedPrimary: {
              backgroundColor: primaryColor.main,
              color: primaryColor.contrastText,

              "&:hover": {
                backgroundColor: primaryColor.dark,
              },
            },
            containedSecondary: {
              background: colors.base.black,
              color: colors.base.white,

              "&:hover": {
                backgroundColor: colors.text.primaryDark,
              },
            },
            outlined: {
              border: `1px solid ${colors.base.outline1}`,
              color: colors.base.outline1,

              "&.Mui-disabled": {
                color: colors.input.disabled,
                border: `1px solid ${colors.input.disabled}`,
                backgroundColor: colors.base.transparent,
              },
            },
            outlinedPrimary: {
              border: `1px solid ${primaryColor.main}`,
              color: primaryColor.main,

              "&:hover": {
                border: `1px solid ${primaryColor.dark}`,
                color: primaryColor.dark,
                backgroundColor: primaryColor.ghost,
              },
              ".MuiTouchRipple-root": {
                color: primaryColor.ghostDark,
              },
            },
            outlinedSecondary: {
              border: `1px solid ${colors.base.outline2}`,
              color: colors.base.outline2,
              backgroundColor: colors.base.transparent,

              "&:hover": {
                border: `1px solid ${colors.text.primaryDark}`,
                color: colors.text.primaryDark,
                backgroundColor: alphaColor(colors.text.primaryDark, 0.025),
              },
              ".MuiTouchRipple-root": {
                color: alphaColor(colors.text.primaryDark, 0.05),
              },
            },
            outlinedError: {
              color: commonTheme.palette.error.main,
              border: `1px solid ${commonTheme.palette.error.main}`,

              "&:hover": {
                border: `1px solid ${commonTheme.palette.error.dark}`,
                color: commonTheme.palette.error.dark,
                backgroundColor: alphaColor(commonTheme.palette.error.main, 0.05),
              },
              ".MuiTouchRipple-root": {
                color: alphaColor(commonTheme.palette.error.main, 0.1),
              },
            },
            outlinedSuccess: {
              color: commonTheme.palette.success.main,
              border: `1px solid ${commonTheme.palette.success.main}`,

              "&:hover": {
                border: `1px solid ${commonTheme.palette.success.dark}`,
                color: commonTheme.palette.success.dark,
                backgroundColor: alphaColor(commonTheme.palette.success.main, 0.05),
              },
              ".MuiTouchRipple-root": {
                color: alphaColor(commonTheme.palette.success.main, 0.1),
              },
            },
            outlinedWarning: {
              color: colors.warning.default,
              border: `1px solid ${colors.warning.default}`,

              "&:hover": {
                border: `1px solid ${commonTheme.palette.warning.dark}`,
                color: commonTheme.palette.warning.dark,
                backgroundColor: alphaColor(colors.warning.default, 0.05),
              },
              ".MuiTouchRipple-root": {
                color: alphaColor(colors.warning.default, 0.1),
              },
            },
            text: {
              minWidth: "initial",
              backgroundColor: colors.base.transparent,

              "&:disabled": {
                color: colors.grey[300],
                backgroundColor: colors.base.transparent,
              },
            },
            textPrimary: {
              "&:hover": {
                backgroundColor: primaryColor.ghost,
              },
              ".MuiTouchRipple-root": {
                color: primaryColor.ghostDark,
              },
            },
            textSecondary: {
              color: colors.grey[700],

              "&:hover": {
                backgroundColor: alphaColor(colors.grey[700], 0.05),
              },
              ".MuiTouchRipple-root": {
                color: alphaColor(colors.grey[700], 0.1),
              },
            },
            textError: {
              color: commonTheme.palette.error.main,

              "&:hover": {
                backgroundColor: alphaColor(commonTheme.palette.error.main, 0.05),
              },
              ".MuiTouchRipple-root": {
                color: alphaColor(commonTheme.palette.error.main, 0.1),
              },
            },
            textSuccess: {
              color: commonTheme.palette.success.main,

              "&:hover": {
                backgroundColor: alphaColor(commonTheme.palette.success.main, 0.05),
              },
              ".MuiTouchRipple-root": {
                color: alphaColor(commonTheme.palette.success.main, 0.1),
              },
            },
            // sizes
            sizeSmall: {
              padding: `${toRem(10)}`,

              ".MuiButton-startIcon": {
                marginRight: toRem(4),
                marginTop: toRem(-2),
                svg: {
                  width: toRem(16),
                  height: toRem(16),
                },
              },
            },
            sizeLarge: {
              padding: `${toRem(20)} ${toRem(8)}`,
            },
          },
          // custom variants
          variants: [
            {
              props: {
                size: "extraSmall",
              },
              style: {
                height: toRem(32),
                fontSize: toRem(12),
                lineHeight: toRem(16),
                padding: `${toRem(2)} ${toRem(12)}`,
                minWidth: toRem(80),

                ".MuiButton-startIcon": {
                  marginRight: toRem(6),
                  svg: {
                    width: toRem(14),
                    height: toRem(14),
                  },
                },
              },
            },
            {
              props: {
                variant: "cta",
              },
              style: {
                padding: 0,
                color: primaryColor.main,
                minWidth: 0,
                backgroundColor: "transparent !important",
                ".MuiTouchRipple-root": {
                  color: "transparent",
                },
                "&.Mui-loading": {
                  color: primaryColor.main,
                  backgroundColor: "transparent !important",
                },
                "&.Mui-disabled": {
                  color: colors.text.disabledDark,
                  backgroundColor: "transparent !important",
                },
              },
            },
            {
              props: {
                variant: "cta",
                color: "secondary",
              },
              style: {
                color: commonTheme.palette.secondary.main,
              },
            },
            {
              props: {
                variant: "cta",
                color: "error",
              },
              style: {
                color: commonTheme.palette.error.main,
              },
            },
            {
              props: {
                variant: "outlined",
                size: "extraSmall",
              },
              style: {
                borderRadius: rounding.default,
              },
            },
          ],
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              transition: "background-color 0.2s ease-in-out",
              "&:hover": {
                backgroundColor: colors.grey[100],
              },
            },
            sizeSmall: {
              padding: toRem(8),
              svg: {
                width: toRem(16),
                height: toRem(16),
              },
            },
            sizeMedium: {
              padding: toRem(8),
              svg: {
                width: toRem(24),
                height: toRem(24),
              },
            },
            sizeLarge: {
              padding: toRem(14),
              svg: {
                width: toRem(32),
                height: toRem(32),
              },
            },
            colorPrimary: {
              color: colors.grey[900],

              "&:hover": {
                color: primaryColor.main,
                backgroundColor: primaryColor.ghost,
              },
            },
          },
          variants: [
            {
              props: {
                color: "white",
              },
              style: {
                color: colors.base.white,

                "&:hover": {
                  backgroundColor: alphaColor(colors.base.black, 0.15),
                },
              },
            },
            {
              props: {
                size: "extraSmall",
              },
              style: {
                padding: toRem(4),
                svg: {
                  width: toRem(12),
                  height: toRem(12),
                },
              },
            },
            {
              props: {
                color: "blured",
              },
              style: {
                backgroundColor: alphaColor(colors.base.black, 0.2),
                color: colors.base.white,
                backdropFilter: "blur(5px)",

                "&:hover": {
                  backgroundColor: alphaColor(colors.base.black, 0.25),
                },
              },
            },
            {
              props: {
                color: "tertiary",
              },
              style: {
                backgroundColor: colors.base.surface1,
                color: colors.grey[900],

                "&:hover": {
                  backgroundColor: colors.base.surface1,
                },
              },
            },
          ],
        },
        MuiTab: {
          styleOverrides: {
            root: {
              color: "rgba(0, 0, 0)",
              fontFamily: "Avenir-heavy",
              fontSize: toRem(14),
              lineHeight: toRem(18),
              borderBottom: `1px solid ${colors.base.outline1}`,
              position: "relative",
              "&:Mui-selected": {
                color: primaryColor.main,
                borderColor: primaryColor.main,
                "&::after": {
                  content: '""',
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: "0.188rem",
                  backgroundColor: primaryColor.main,
                  borderRadius: "0.625rem 0.625rem 0 0",
                },
              },
            },
            textColorPrimary: {
              color: colors.text.secondaryDark,
              borderColor: "transparent",
            },
          },
        },
        MuiTabs: {
          styleOverrides: {
            root: {
              minHeight: "40px",
            },
            indicator: {
              backgroundColor: primaryColor.main,
              height: "1px",
              marginTop: "30px",
            },
            scroller: {
              "&:before": {
                content: '""',
                position: "absolute",
                bottom: 0,
                width: "100%",
                height: "1px",
                background: colors.base.outline1,
              },
            },
          },
        },
        MuiChip: {
          styleOverrides: {
            root: {
              borderRadius: rounding.default,
              fontSize: toRem(12),
              lineHeight: toRem(16),
              fontFamily: "Avenir",
              fontWeight: 500,
            },
            label: {
              padding: `${toRem(8)} ${toRem(10)}`,
            },
            filledPrimary: {
              border: `1px solid ${primaryColor.main}`,
            },
            filledSecondary: {
              color: colors.text.secondaryDark,
              backgroundColor: colors.grey[100],
            },
            colorSecondary: {
              color: colors.text.tertiaryDark,
            },
            deleteIcon: {
              color: colors.base.white,
              fontSize: "0.875rem",
            },
          },
        },
        MuiFormLabel: {
          styleOverrides: {
            root: {
              fontSize: toRem(14),
              lineHeight: toRem(20),
              color: colors.text.primaryDark,
            },
          },
        },
        MuiFormHelperText: {
          styleOverrides: {
            root: {
              fontSize: toRem(12),
              lineHeight: toRem(16),
              fontWeight: 500,
              marginLeft: 0,
              marginTop: toRem(6),
            },
          },
        },
        MuiSnackbar: {
          styleOverrides: {
            root: {
              "& .MuiAlert-root": {
                maxWidth: "620px",
                minWidth: "420px",
                justifyContent: "center",
                [commonTheme.breakpoints.down("sm")]: {
                  minWidth: "inherit",
                  width: "100%",
                },
              },
            },
            anchorOriginTopCenter: {
              top: "70px !important",
              [commonTheme.breakpoints.down("sm")]: {
                top: "60px !important",
              },
            },
          },
        },
        MuiAlert: {
          styleOverrides: {
            root: {
              fontSize: toRem(14),
              background: colors.base.white,
              border: `1px solid ${colors.base.outline1}`,
              borderRadius: toRem(8),
              padding: `${toRem(12)} ${toRem(16)}`,
              alignItems: "center",
            },
            message: {
              padding: `${toRem(2)} 0`,
              textAlign: "center",
              fontSize: toRem(14),
              lineHeight: toRem(20),
            },
            standardSuccess: {
              border: `1px solid ${colors.success.default}`,
              backgroundColor: colors.success[100],
            },
            standardError: {
              border: `1px solid ${colors.error.default}`,
              backgroundColor: colors.error[100],
            },
            standardWarning: {
              border: `1px solid ${colors.warning.default}`,
              backgroundColor: colors.warning[100],
            },
          },
        },
        MuiTableContainer: {
          styleOverrides: {
            root: {
              borderRadius: toRem(10),
              border: `1px solid ${colors.grey[300]}`,
              boxShadow: "none",
            },
          },
        },
        MuiTableHead: {
          styleOverrides: {
            root: {
              backgroundColor: colors.base.surface1,
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              fontSize: toRem(14),
              borderBottom: `1px solid ${colors.grey[300]}`,
              padding: `${toRem(8)} ${toRem(16)}`,
            },
          },
        },
        MuiSwitch: {
          styleOverrides: {
            root: {
              width: toRem(40),
              height: toRem(24),
              padding: 0,
              borderRadius: toRem(1000),
            },
            switchBase: {
              color: colors.grey[100],

              "& + .MuiSwitch-track": {
                backgroundColor: colors.grey[600],
                borderColor: colors.grey[600],
                opacity: 1,
                transition: "background-color 0.2s ease-in-out",
              },

              "&:hover": {
                "& + .MuiSwitch-track": {
                  backgroundColor: primaryColor.light,
                },
              },

              "&.Mui-checked": {
                transform: "translateX(1rem)",
                color: colors.grey[100],

                "& + .MuiSwitch-track": {
                  backgroundColor: primaryColor.main,
                  borderColor: primaryColor.main,
                  opacity: 1,
                },
              },

              "&.Mui-disabled": {
                "& + .MuiSwitch-track": {
                  backgroundColor: colors.base.outline1,
                  borderColor: colors.base.outline1,
                  opacity: 1,
                },

                "&.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: alphaColor(primaryColor.main, 0.2),
                  borderColor: alphaColor(primaryColor.main, 0.2),
                  opacity: 1,
                },

                "& .MuiSwitch-thumb": {
                  position: "relative",
                  backgroundColor: colors.base.white,

                  "&:before": {
                    content: "''",
                    position: "absolute",
                    width: "9px",
                    height: "10px",
                    left: "5.75px",
                    top: "5px",
                    backgroundImage: `url('data:image/svg+xml;utf8,<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.3125 2.5V3.75H6.4375V2.5C6.4375 1.63672 5.73828 0.9375 4.875 0.9375C4.01172 0.9375 3.3125 1.63672 3.3125 2.5ZM2.375 3.75V2.5C2.375 1.11914 3.49414 0 4.875 0C6.25586 0 7.375 1.11914 7.375 2.5V3.75H8C8.68945 3.75 9.25 4.31055 9.25 5V8.75C9.25 9.43945 8.68945 10 8 10H1.75C1.06055 10 0.5 9.43945 0.5 8.75V5C0.5 4.31055 1.06055 3.75 1.75 3.75H2.375ZM1.4375 5V8.75C1.4375 8.92188 1.57812 9.0625 1.75 9.0625H8C8.17188 9.0625 8.3125 8.92188 8.3125 8.75V5C8.3125 4.82812 8.17188 4.6875 8 4.6875H1.75C1.57812 4.6875 1.4375 4.82812 1.4375 5Z" fill="${encodeURIComponent(
                      alphaColor(primaryColor.main, 0.2)
                    )}"/></svg>')`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  },
                },
              },
            },
            thumb: {
              width: toRem(20),
              height: toRem(20),
              marginTop: "-7px",
              marginLeft: "-7px",
              boxShadow: "none",
            },
            colorSecondary: {
              "& + .MuiSwitch-track": {
                backgroundColor: colors.brand[400],
                borderColor: colors.brand[400],
              },
            },
          },
        },
        MuiTooltip: {
          defaultProps: {
            placement: "top",
            arrow: true,
          },
          styleOverrides: {
            tooltip: {
              backgroundColor: colors.base.black,
              color: colors.text.tertiaryLight,
              fontSize: toRem(14),
              lineHeight: toRem(24),
              padding: `${toRem(8)} ${toRem(12)}`,
              borderRadius: rounding.lg,
              boxShadow: effects.shadow.base,
              maxWidth: "256px",
              a: {
                color: colors.text.tertiaryLight,
              },
            },
            arrow: {
              color: colors.base.black,
            },
            popper: {
              "&[data-popper-placement*='top'] .MuiTooltip-tooltip": {
                marginBottom: "8px !important",
              },
              "&[data-popper-placement*='bottom'] .MuiTooltip-tooltip": {
                marginTop: "8px !important",
              },
              "&[data-popper-placement*='-start']": {
                left: `-16px !important`,

                ".MuiTooltip-arrow": {
                  left: `16px !important`,
                },
              },
              "&[data-popper-placement*='-end']": {
                right: `-16px !important`,

                ".MuiTooltip-arrow": {
                  left: `-16px !important`,
                },
              },
            },
          },
        },
        MuiPagination: {
          styleOverrides: {
            ul: {
              justifyContent: "center",
            },
          },
        },
        MuiPaginationItem: {
          styleOverrides: {
            root: {
              width: toRem(40),
              height: toRem(40),
              fontSize: toRem(14),
              lineHeight: toRem(24),
              padding: `${toRem(8)} ${toRem(16)}`,
              borderRadius: rounding.lg,
              color: colors.text.secondaryDark,
              "&:hover": {
                backgroundColor: primaryColor.ghost,
              },
              "&.Mui-selected": {
                color: colors.base.white,
                backgroundColor: primaryColor.main,

                "&:hover": {
                  backgroundColor: primaryColor.dark,
                },
              },
            },
          },
        },
        MuiFormControlLabel: {
          styleOverrides: {
            label: {
              fontSize: toRem(14),
              lineHeight: toRem(20),
              display: "flex",
              alignItems: "center",
              gap: toRem(4),
              userSelect: "none",

              svg: {
                width: toRem(16),
                height: toRem(16),
              },
            },
          },
        },
        MuiAccordion: {
          styleOverrides: {
            root: {
              boxShadow: "none",
              border: `1px solid ${colors.input.border}`,
              borderRadius: `${rounding.default} !important`,
              overflow: "hidden",
              margin: "1rem 0",

              "&::before": {
                opacity: 0,
              },
            },
          },
        },
        MuiAccordionSummary: {
          styleOverrides: {
            root: {
              padding: `${toRem(8)} ${toRem(16)}`,
              backgroundColor: colors.base.white,
              border: "none",
              minHeight: "3rem",

              "&.Mui-expanded": {
                backgroundColor: colors.base.surface1,
                minHeight: "3rem",
              },
            },
            content: {
              fontSize: toRem(14),
              lineHeight: toRem(20),
              margin: 0,

              "&.Mui-expanded": {
                margin: 0,
              },
            },
          },
        },
        MuiAccordionDetails: {
          styleOverrides: {
            root: {
              padding: "1rem",
            },
          },
        },
        MuiSlider: {
          styleOverrides: {
            valueLabel: {
              fontSize: toRem(14),
              borderRadius: rounding.default,
              backgroundColor: colors.base.black,
            },
          },
        },
        MuiRadio: {
          styleOverrides: {
            root: {
              padding: 6,
            },
            sizeSmall: {
              svg: {
                width: 24,
                height: 24,
              },
            },
          },
        },
        MuiCheckbox: {
          styleOverrides: {
            root: {
              padding: 6,
            },
            sizeMedium: {
              svg: {
                width: 24,
                height: 24,
              },
            },
          },
        },
      },
    });
  }, [args.primary]);

  return theme;
};
