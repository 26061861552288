import { SuperAdminApi } from "#apis/superAdmin/api";
import { SuperAdminQueryKeys } from "#apis/superAdmin/queryKeys";
import {
  SearchOrganisationsRequest,
  SearchOrganisationsResponse,
} from "#customTypes/admin";
import { AppInfiniteQueryOptions, AppInfiniteQueryProps } from "#customTypes/reactQuery";
import { getInfiniteQueryOptions } from "#utils/paginationUtils";
import { useInfiniteQuery } from "@tanstack/react-query";

type Params = AppInfiniteQueryProps<SearchOrganisationsRequest>;

interface Props {
  params: Params;
  options?: AppInfiniteQueryOptions<SearchOrganisationsResponse, (string | Params)[]>;
}

const PAGE_SIZE = 100;

export default function useSearchOrganisations({ params, options }: Props) {
  return useInfiniteQuery({
    queryKey: [...SuperAdminQueryKeys.searchOrganisations, { ...params }],
    queryFn: ({ pageParam }) =>
      SuperAdminApi.searchOrganisations({
        ...params,
        page: pageParam,
        pageSize: PAGE_SIZE,
      }),
    ...getInfiniteQueryOptions(),
    ...options,
    placeholderData: (prev) => prev,
  });
}
